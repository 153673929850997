<template>
  <template v-if="!loading">
    <Nav />
    <div class="container">
      <router-view />
      <Notificaciones v-if="showNotifications" />
      <FooterComponent /></div
  ></template>
  <template v-else>
    <Loader class="fondo" />
  </template>
</template>

<script>
import Nav from "@/components/Nav.vue";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { app } from "@/components/firebase/index.js";
import Notificaciones from "./components/Notificaciones.vue";
import { onMounted, ref, watch } from "vue";
// import Mantenimiento from "./components/Mantenimiento.vue";
import { axiosInstance } from "./axios.js";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import FooterComponent from "./components/Footer.vue";
import Loader from "./loader.vue";
export default {
  components: {
    Nav,
    Notificaciones,
    FooterComponent,
    Loader,
  },
  setup() {
    const auth = getAuth(app);
    const mantenimiento = ref(true);
    const showNotifications = ref(false);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const loading = ref(true);

    function antiLag() {
      if (store.getters["User/logeado"] && store.getters["User/uid"])
        return false;
      else return true;
    }

    async function obtenerNotificacionesPendientes(uid) {
      try {
        const response = await axiosInstance.get(
          "notificaciones/inAppNotificationsPendientes",
          {
            params: {
              uid: uid,
            },
          }
        );
        if (response.data.ok) {
          await store.dispatch(
            "Notificaciones/setNotificacionesPendientes",
            response.data.notificaciones
          );
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function initializeAuthListener() {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const userToken = await user.getIdToken(true);
            store.dispatch("User/setToken", userToken); // Obligación que esto vaya primero que las llamadas de axios

            if (antiLag()) {
              let customClaims = null;

              if (user.reloadUserInfo.customAttributes) {
                customClaims = JSON.parse(user.reloadUserInfo.customAttributes);
              }

              store.dispatch("User/setInfoUsuario", {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                logeado: true,
                customClaims,
                token: userToken,
              });

              const sqlUser = await axiosInstance.get(
                "trabajadores/getTrabajadorByAppId",
                { params: { uid: user.uid } }
              );
              if (!sqlUser.data.ok) throw Error(sqlUser.data?.message);
              store.dispatch("User/setIdSql", {
                idSql: sqlUser.data.data.id,
                idTienda: sqlUser.data.data.idTienda,
                llevaEquipo: sqlUser.data.data.llevaEquipo,
                nombreTienda: sqlUser.data.data.tienda?.nombre ?? null,
                displayFoto: sqlUser.data.data.displayFoto,
                dni: sqlUser.data.data.dni,
                telefono: sqlUser.data.data.telefonos,
                roles: sqlUser.data.data.roles,
                permissions: await getPermisos(sqlUser.data.data),
              });
              store.dispatch("User/setNombre", sqlUser.data.data.displayName);

              await obtenerNotificacionesPendientes(user.uid);
            }
          } catch (err) {
            console.log(err);
            Swal.fire("Oops...", err.message, "error");
          }
          if (router.currentRoute.value.path === "/login") router.push("/");
        } else {
          store.dispatch("User/clearUser");
          // if (router.currentRoute.value.path != "/login") router.push("/login");
        }
        loading.value = false;
      });
    }

    // axiosInstance
    //   .post("mantenimiento/getEstado")
    //   .then((resEstado) => {
    //     mantenimiento.value = resEstado.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    //No mostras las notificaciones en la url especificada ni recargando la pagina
    const checkRoute = () => {
      showNotifications.value = route.path !== "/notificaciones";
    };

    function esperarSegundos(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function getPermisos(role) {
      try {
        // Hacer todas las solicitudes para obtener los roles del usuario
        const roleRequests = role.roles.map((role) =>
          axiosInstance.get("role/getRoleId", {
            params: { id: role.id },
          })
        );

        // Esperar a que todas las solicitudes se completen
        const responses = await Promise.all(roleRequests);
        const permissionsArray = responses.map(
          (response) => response.data.data.permissions
        );

        return permissionsArray.flat();
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    onMounted(() => {
      initializeAuthListener();
      setTimeout(() => {
        checkRoute;
      }, 1000);
    });

    watch(
      () => route.path,
      () => {
        checkRoute();
      }
    );

    return {
      showNotifications,
      route,
      mantenimiento,
      loading,
      esperarSegundos,
    };
  },
};
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.fondo {
  background-color: #060d1d;
}
</style>
