import { app, initializeFCM } from "./index";
import {
  GoogleAuthProvider,
  signOut,
  getAuth,
  deleteUser,
  signInWithRedirect,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  connectAuthEmulator,
  signInWithCustomToken as signInWithCustomTokenCompat,
} from "firebase/auth";

import router from "../../router/index";
import Swal from "sweetalert2";
// import { computed } from "vue";

const provider = new GoogleAuthProvider();
const auth = getAuth(app);

if (process.env.NODE_ENV === "development_auth") {
  connectAuthEmulator(auth, "http://localhost:9099");
}

export function getOnlyUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}

export function checkLogin() {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user ? true : false);
    });
  });
}

export async function getCurrentUserToken() {
  return checkLogin()
    .then(async (user) => {
      if (user) return await user.getIdToken();

      return false;
    })
    .catch((err) => {
      console.log(err);
      Swal.fire("Oops...", err.message, "error");
      return false;
    });
}

export function logout() {
  localStorage.setItem("shopMode", "false");
  signOut(auth);
  router.push("/login");
}

export function loginGoogleWithRedirect() {
  signInWithRedirect(auth, provider);
}

export async function signInWithCustomToken(customToken) {
  try {
    const userCredential = await signInWithCustomTokenCompat(auth, customToken);

    if (userCredential) router.push("/");
  } catch (error) {
    console.error("Error signing in with custom token:", error);
  }
}

/* Entrar con tipo email + contraseña */
export function accederConEmail(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      Swal.fire(
        "Inicio de sesión correcto",
        "Bienvenid@ al portal de 365Obrador " + userCredential.user.displayName,
        "success"
      );
      localStorage.setItem("loggedIn", "true");
      router.push("/");

      // Llamamos a initializeFCM después del inicio de sesión exitoso
      initializeFCM();
    })
    .catch((err) => {
      console.log(err);
      Swal.fire("Oops...", err.message, "error");
    });
}

/* Crear usuario de tipo email + contraseña */
export function createUser(email, password) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      Swal.fire(
        "OK",
        `¡${userCredential.user.email} registrado correctamente!`,
        "success"
      );
      router.push("/");
    })
    .catch((err) => {
      console.log(err);
      Swal.fire("Oops...", err.message, "error");
    });
}

export function eliminarCuentaAuth() {
  const user = auth.currentUser;
  return deleteUser(user)
    .then(() => {
      return 200;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function restablecerContraseña(email) {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      Swal.fire({
        icon: "success",
        title: "Perfecto...",
        html: `Hemos enviado un email al correo <span class="text-primary">${email}</span> para restablecer la constraseña`,
        showConfirmButton: true,
      }).then(function () {
        router.push("/login");
      });
    })
    .catch((error) => {
      if (error.message == "Firebase: Error (auth/invalid-email).") {
        Swal.fire({
          icon: "error",
          title: "Ups...",
          text: `Esto no es un correo`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      if (error.message == "Firebase: Error (auth/user-not-found).") {
        Swal.fire({
          icon: "error",
          title: "Ups...",
          html: `El correo <span class="text-primary">${email}</span> no existe en nuestra base de datos`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    });
}
